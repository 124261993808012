<template>
    <div class="slider" :style="{
                    '--bg': bg,
                    '--border-color': borderColor,
                    '--thumb-color': thumbColor,
                    '--height': height,
                }">
        <label v-if="label"> {{ label }} </label>
        <div>
            <input @change="updateValue"
                   ref="slider"
                   type="range"
                   :min="min"
                   :max="max"
                   :step="step"
                   :value="value"
                   :disabled="disabled">
        </div>
        <span v-if="error" class="has-error animated headShake">{{ error }}</span>
    </div>
</template>

<script>
    export default {
        name: "RangeSlider",
        props: {
            step:  [String, Number],
            min:   [String, Number],
            max:   [String, Number],
            value: [String, Number],
            label: [String, Number],
            error: {type: String},
            disabled: {type: Boolean, default: false},
            change: {type: Function},
            bg: {type: String, default: 'white'},
            thumbColor: {type: String, default: '#221ecd'},
            borderColor: {type: String, default: '#e7e7f0'},
            height: {type: String, default: '8px'},
        },
        methods: {
            updateValue() {
                this.$emit('input', +this.$refs.slider.value);
                if (this.change) this.change(+this.$refs.slider.value);
            }
        }
    }
</script>

<style scoped lang="scss">

    .slider {
        position: relative;
        height: 40px;
        width: 100%;

        label {
            display: block;
            margin-bottom: 5px;
        }

        div {
            align-items: center;
            display: flex;
            height: 40px;
            width: 100%;

            input {
                -webkit-appearance: none;
                height: var(--height);
                border-radius: 3px;
                flex: 1;
                background: var(--bg);
                border: 1px solid var(--border-color);
                outline: none;
            }

            input::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--thumb-color);
                cursor: pointer;
            }

            input::-moz-range-thumb {
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--thumb-color);
                cursor: pointer;
            }
        }

        .has-error {
            position: absolute;
            bottom: -14px;
            font-size: .7rem;
            right: 5px;
            color: color(bg-red);
            font-weight: $font-medium;
        }

        @keyframes bounce {
            from,
            20%,
            53%,
            80%,
            to {
                -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }

            40%,
            43% {
                -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                -webkit-transform: translate3d(0, -30px, 0);
                transform: translate3d(0, -30px, 0);
            }

            70% {
                -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                -webkit-transform: translate3d(0, -15px, 0);
                transform: translate3d(0, -15px, 0);
            }

            90% {
                -webkit-transform: translate3d(0, -4px, 0);
                transform: translate3d(0, -4px, 0);
            }
        }

        .bounce {
            -webkit-animation-name: bounce;
            animation-name: bounce;
            -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
        }
    }
</style>
