<template>
  <div class="radio" :class="{ 'disabled': disabled }">
    <div :class="{ 'radio__circle': type==='circle', 'radio__box': type==='box' }">
      <input
          :id="id"
          ref="radio"
          :checked="checked"
          :disabled="disabled"
          :name="name"
          type="radio"
          @input="updateCheck"
      >
      <div :class="{ 'radio-circle': type==='circle', 'radio-box': type==='box' }"/>
    </div>
    <label :for="id" v-if="text" :style="{
                  '--text-width': textWidth,
                  '--font-size': fSize,
                  '--font-color': fColor
                  }">
      {{ text }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioButton',
  components: {},
  props: {
    change: { type: Function },
    check: { type: Function },
    uncheck: { type: Function },
    id: String,
    text: { type: String, default: '' },
    bindValue: [String, Object],
    name: { type: String, default: '' },
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    textWidth: { type: String, default: '100%' },
    fSize: { type: String, default: '.8rem' },
    fColor: { type: String, default: '#495057' },
    type: { type: String, enum: ['box', 'pill', 'pill-sm', 'circle'], default: 'circle' }
  },
  methods: {
    updateCheck() {
      this.$emit('input', this.bindValue);
      if ( this.change ) this.change(this.bindValue);
      if ( this.$refs.radio.checked && this.check ) this.check();
      if ( !this.$refs.radio.checked && this.uncheck ) this.uncheck();
    }
  }
}
</script>

<style scoped lang="scss">

.radio {
  $text-width: '50px';
  line-height: 23px;
  min-width: 23px;
  min-height: 20px;
  font-size: .8rem;
  display: flex;
  align-items: center;

  &.disabled {

    .radio-circle,
    .radio-box,
    input:checked ~ .radio-circle,
    input:checked ~ .radio-box {
      border: none;
      background: color(bg-gray3);
    }

    input {
      cursor: not-allowed;
    }
  }

  &__box {
    position: relative;
    border-radius: .3125rem;
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: flex;

    .radio-box, input {
      position: absolute;
      border-radius: .3125rem;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    input {
      cursor: pointer;
      opacity: 0;
      z-index: 9999999;
    }

    .radio-box {
      border: 1px solid color(bg-primary);
    }

    input:checked ~ .radio-box {
      border: 5px solid color(bg-primary);
    }
  }

  &__circle {
    position: relative;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: flex;

    .radio-circle, input {
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    input {
      cursor: pointer;
      opacity: 0;
      z-index: 9999999;
    }

    .radio-circle {
      border: 1px solid color(bg-primary);
    }

    input:checked ~ .radio-circle {
      border: 5px solid color(bg-primary);
    }
  }

  label {
    font-size: var(--font-size);
    color: var(--font-color);
    min-height: 23px;
    white-space: nowrap;
    margin-left: 1.22rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

</style>
